import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthService, NbAuthOAuth2JWTToken } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { of as observableOf } from 'rxjs';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './services';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../@core/interceptor/jwt.interceptor';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const NB_CORE_PROVIDERS = [
  { provide: NbAuthService, useClass: AuthenticationService },
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.apiServer,
        token: {
          class: NbAuthOAuth2JWTToken,
          getter: function (method: any, response: any) {
            if (response.body.token) {
              return {
                access_token: response.body.token.access,
                refresh_token: response.body.token.refresh,
                user: response.body.user
              };
            }

            let saved_token = JSON.parse(localStorage.getItem('auth_app_token'));
            if (saved_token) {
              saved_token = JSON.parse(saved_token.value);
            }

            return {
              access_token: response.body.access,
              refresh_token: saved_token.refresh_token,
              user: response.body.user
            }
          },
        },
        refreshToken: {
          endpoint: '/token/refresh/',
          method: 'post',
          requireValidToken: true,
          redirect: {
            success: null,
            failure: null,
          },
          defaultErrors: ['Something went wrong, please try again.'],
          defaultMessages: ['Your token has been successfully refreshed.'],
        },
        login: {
          endpoint: '/auth/login/',
          redirect: {
            success: '/admin',
            failure: null,
          }
        },
      }),
    ],
  }).providers,
  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  {
    provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
  },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
