import { EventEmitter, Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { merge } from 'lodash';
import { get } from '../utils';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class DialogService {

    private SwalRef: any;

    private swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary btn-lg",
        cancelButton: "btn btn-outline-secondary btn-lg"
      },
      buttonsStyling: false
    });

    constructor() { }

    private emitOnClose(errorCode?: number) {
        const onClose = new EventEmitter<any>();
        this.SwalRef.then((result: any) => {
            onClose.emit(errorCode || result.value);
        }, () => onClose.error('close')).catch(() => {
        });
        return onClose;
    }

    /**
     * Success sweet alert
     * @param successmsg modal content
     */

    success(title?: string, message?: string) {
        this.SwalRef = this.swalWithBootstrapButtons.fire({
            position: 'top-end',
            icon: 'success',
            target: '#alert-wrapper',
            title: title || 'Completado',
            html: message || 'Operación completada con éxito',
            showConfirmButton: false,
            allowOutsideClick: true,
            allowEscapeKey: true,
            toast: true,
            backdrop: false,
            timer: 2500,
          }
        );

        return this.emitOnClose(200);
    }

    close(){
      this.SwalRef = this.swalWithBootstrapButtons.close()
    }

    loading(title?: string, message?: string) {
      if (!message) {
        message = title;
        title = 'Procesando';
      }
      this.SwalRef = this.swalWithBootstrapButtons.fire({
          position: 'top-end',
          icon: 'info',
          target: '#alert-wrapper',
          title: title,
          html: message,
          toast: true,
          showConfirmButton: false,
          allowOutsideClick: false,
          backdrop: false,
          allowEscapeKey: false,
          timer: 0,
        }
      );
  }

    /**
     * Confirm sweet alert
     * @param confirm modal content
     */
    confirm(data?: any) {
        this.SwalRef = this.swalWithBootstrapButtons.fire({
            title: data?.title || 'Confirmación',
            text: data?.message || '',
            icon: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: data?.confirmText || 'Confirmar',
            cancelButtonText: data?.cancelText || 'Cancelar',
            reverseButtons: true
        });

        return this.emitOnClose();
    }

    error(error: any, title=null) {
        if (!!error.handled) {
            return new EventEmitter<any>();
        }
        if (!environment.production) {
            console.log(error);
        }

        if (typeof error === 'string' || error instanceof String) {
            error = {
            title: title || 'Ha ocurrido un error',
            message: error
          }
        } else if (title) {
            error.title = title;
        }
        const data: any = merge({
            icon: 'warning',
            confirmButtonText: 'Cerrar'
          },
          this.processErrorResponses(error)
        );

        this.SwalRef = this.swalWithBootstrapButtons.fire(data);

        return this.emitOnClose(get(error, 'status', ''));
    }


    private isValidationErrorFomat(error: any) {
        return (Array.isArray(error) && 'field' in error[0]);
    }

    private processErrorResponses(error: any) {
        if (get(error, 'status') === 404) {
            const pattern = /^[a-z][\w-]*(?:\.[\w-]+)*$/i;
            const msg = get(error, 'error.detail', false) && pattern.test(error.error.detail)
                ? `ALERT.MESSAGE.BACKEND.${get(error, 'error.detail', 'No encontrado')}`
                : 'El recurso no ha sido encontrado';
            return {
                title: 'No encontrado',
                text: msg
            }
        }

        else if (get(error, 'status') === 401) {
            return {
                title: 'Acceso restringido',
                text: 'No estas autorizado para ver este recurso'
            }
        }
        else if (get(error, 'status') === 403) {
            return {
                title: 'Acceso prohibido',
                text: 'No tienes permisos para acceder a este recurso'
            }
        }

        else if (get(error, 'status') === 400 || this.isValidationErrorFomat(error)) {
            const validationErrors = <any>[];
            const errors = get(error, 'error.errors') || error;
            errors.map((item: any) => {
                item.errors.forEach((e: any) => {
                    validationErrors.push(`<div>${item.field}: ${e}</div>`);
                });

            });
            return {
                title: 'El formulario no es válido',
                html: validationErrors.join('')
            }
        }

        else if (get(error, 'status') === 500) {
            return {
                title: error?.title || 'Ups, error',
                text: error?.message || 'Ha ocurrido un error'
            }
        }

        else {
            return {
              title: error?.title || 'Ups, error',
              text: error?.message || 'Ha ocurrido un error'
            };
        }
    }
}
