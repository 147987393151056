import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LayoutService } from '../../../@core/services';
import { UrlService } from '../../../@core/services/url.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userImage = `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMy43NUM2LjM0Mzc1IDMuNzUgNSA1LjA5Mzc1IDUgNi43NUM1IDguNDA2MjUgNi4zNDM3NSA5Ljc1IDggOS43NUM5LjY1NjI1IDkuNzUgMTEgOC40MDYyNSAxMSA2Ljc1QzExIDUuMDkzNzUgOS42NTYyNSAzLjc1IDggMy43NVpNOCA4LjI1QzcuMTU2MjUgOC4yNSA2LjUgNy41OTM3NSA2LjUgNi43NUM2LjUgNS45Mzc1IDcuMTU2MjUgNS4yNSA4IDUuMjVDOC44MTI1IDUuMjUgOS41IDUuOTM3NSA5LjUgNi43NUM5LjUgNy41OTM3NSA4LjgxMjUgOC4yNSA4IDguMjVaTTggMC43NUMzLjcxODc1IDAuNzUgMC4yNSA0LjIxODc1IDAuMjUgOC41QzAuMjUgMTIuNzgxMiAzLjcxODc1IDE2LjI1IDggMTYuMjVDMTIuMjgxMiAxNi4yNSAxNS43NSAxMi43ODEyIDE1Ljc1IDguNUMxNS43NSA0LjIxODc1IDEyLjI4MTIgMC43NSA4IDAuNzVaTTggMTQuNzVDNi40Mzc1IDE0Ljc1IDUgMTQuMTg3NSAzLjkwNjI1IDEzLjI1QzQuMzc1IDEyLjUzMTIgNS4xODc1IDEyLjAzMTIgNi4wOTM3NSAxMi4wMzEyQzYuNzUgMTIuMjE4OCA3LjM3NSAxMi4zMTI1IDggMTIuMzEyNUM4LjU5Mzc1IDEyLjMxMjUgOS4yMTg3NSAxMi4yMTg4IDkuODc1IDEyLjAzMTJDMTAuNzgxMiAxMi4wNjI1IDExLjU5MzggMTIuNTMxMiAxMi4wNjI1IDEzLjI1QzEwLjk2ODggMTQuMTg3NSA5LjUzMTI1IDE0Ljc1IDggMTQuNzVaTTEzLjA2MjUgMTIuMTI1QzEyLjMxMjUgMTEuMTU2MiAxMS4xMjUgMTAuNSA5Ljc4MTI1IDEwLjVDOS40Njg3NSAxMC41IDguOTY4NzUgMTAuODEyNSA4IDEwLjgxMjVDNyAxMC44MTI1IDYuNSAxMC41IDYuMTg3NSAxMC41QzQuODQzNzUgMTAuNSAzLjY1NjI1IDExLjE1NjIgMi45MDYyNSAxMi4xMjVDMi4xNTYyNSAxMS4xMjUgMS43NSA5Ljg3NSAxLjc1IDguNUMxLjc1IDUuMDYyNSA0LjUzMTI1IDIuMjUgOCAyLjI1QzExLjQzNzUgMi4yNSAxNC4yNSA1LjA2MjUgMTQuMjUgOC41QzE0LjI1IDkuODc1IDEzLjgxMjUgMTEuMTI1IDEzLjA2MjUgMTIuMTI1WiIgZmlsbD0iIzAwNTNBMSIvPgo8L3N2Zz4K`;

  userMenu = [ {title: 'Salir', action: 'logout'} ];

  constructor(private sidebarService: NbSidebarService,
              private authService: NbAuthService,
              private menuService: NbMenuService,
              private url: UrlService,
              private router: Router,
              private layoutService: LayoutService) {

      this.authService.getToken().pipe(
        tap((token: any) => {
          this.user = token.payload.user;
        })
      ).subscribe();

      this.menuService.onItemClick()
          .subscribe(
            (menuBag: any) => {
              switch(menuBag.item.action) {
                case 'logout':
                  this.signOut();
                  break;
                  case 'profile':
                    this.profile();
                    break;
                default:
              }
            }
      );
  }
  ngOnInit() {

  //  this.user = { name: 'Nick Jones', picture: 'assets/images/nick.png' };
  }

  public get name() : string {
    return this.user?.first_name ? `${this.user?.first_name} ${this.user?.last_name}` : '';
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  signOut() {
    this.authService.logout('email');
    window.location.href=this.url.login();
  }

  profile() {
    this.router.navigate(['/admin/profile']);
  }
}
