<ul class="nav mb-1">
    <ng-container *ngFor="let breadcrumb of breadcrumbs; let last=last">
        <li
          *ngIf="breadcrumb.label != '__'"
          class="breadcrumb-item"
          [routerLinkActiveOptions]="{exact: true}"
          [routerLinkActive]="last ? 'breadcrumb-active' : ''">
          <a [routerLink]="breadcrumb.url">
              <i [ngClass]="breadcrumb.icon" *ngIf="breadcrumb?.icon"></i>
              {{breadcrumb.label}}
          </a>
        </li>
        <!-- <app-breadcrumb-dynamic *ngIf="breadcrumb.dynamic" [data]="instance" [ref]="breadcrumb"></app-breadcrumb-dynamic> -->
    </ng-container>
    <li
          *ngIf="add"
          class="breadcrumb-item breadcrumb-active">
          <a href="javascript:void(0)">
              {{add}}
          </a>
        </li>
</ul>
