<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <img src="/assets/images/logo-white.png" />
    </a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="user-action">
      <nb-user
          [name]="name"
          [picture]="userImage">
      </nb-user>
    </nb-action>
    <nb-action class="user-action">
      <a href="javascript:void(0)" (click)="signOut()">
        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 0.5C1.34375 0.5 0 1.84375 0 3.5V9.5C0 11.1562 1.34375 12.5 3 12.5H5.625C5.8125 12.5 6 12.3438 6 12.125V11.375C6 11.1875 5.8125 11 5.625 11H3C2.15625 11 1.5 10.3438 1.5 9.5V3.5C1.5 2.6875 2.15625 2 3 2H5.625C5.8125 2 6 1.84375 6 1.625V0.875C6 0.6875 5.8125 0.5 5.625 0.5H3ZM10.2188 1.125L9.59375 1.75C9.4375 1.875 9.4375 2.125 9.59375 2.28125L13.125 5.6875H5.375C5.15625 5.6875 5 5.875 5 6.0625V6.9375C5 7.15625 5.15625 7.3125 5.375 7.3125H13.125L9.59375 10.75C9.4375 10.9062 9.4375 11.1562 9.59375 11.2812L10.2188 11.9062C10.3438 12.0625 10.5938 12.0625 10.75 11.9062L15.875 6.78125C16.0312 6.625 16.0312 6.40625 15.875 6.25L10.75 1.125C10.5938 0.96875 10.3438 0.96875 10.2188 1.125Z" fill="#57636C"/>
        </svg>
      </a>
    </nb-action>
  </nb-actions>
</div>
