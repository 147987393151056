import { Component, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { get } from '../../../@core/utils';

export interface Breadcrumb {
  label: string;
  url: string;
  icon?: any;
  dynamic: string;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {

    @Input() add;
    @Input() dynamic_route;
    @Input() dynamic_value;
    @Input() dynamic_param;

    isHome = true;
    tempState = [];
    breadcrumbs: any[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit() {
      this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root, '/admin');
    }

    ngOnChanges(changes: SimpleChanges): void {
      if (get(changes, 'dynamic_value.currentValue')) {

        const found = this.breadcrumbs.findIndex(item => item.dynamic == this.dynamic_param);

        if (found >= 0) {
          this.breadcrumbs[found]['label'] = this.dynamic_value;
          setTimeout(() => {
            if (this.dynamic_route) {
              this.breadcrumbs[found]['url'] = this.dynamic_route;
            }
          });
        }
      }
    }

    buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
      //If no routeConfig is avalailable we are on the root path

      let label = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.title : '';
      let path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '';

      const breadcrumb = {
        label: label,
      } as Breadcrumb;

      // If the route is dynamic route such as ':id', remove it
      // const lastRoutePart = path.split('/').pop();
      // const isDynamicRoute = lastRoutePart.startsWith(':');
      // if(isDynamicRoute && !!route.snapshot) {
      //   const paramName = lastRoutePart.split(':')[1];
      //   path = path.replace(lastRoutePart, route.snapshot.params[paramName]);
      //   breadcrumb.label = '__';
      //   breadcrumb.dynamic = paramName;
      // }

      //In the routeConfig the complete path is not available,
      //so we rebuild it each time
      const nextUrl = path ? `${url}/${path}` : url;

      breadcrumb.url = nextUrl;

      // Only adding route with non-empty label
      const newBreadcrumbs = breadcrumb.label ? [ ...breadcrumbs, breadcrumb ] : [ ...breadcrumbs];
      if (route.firstChild) {
          //If we are not on our current path yet,
          //there will be more children to look after, to build our breadcumb
          return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
      }
      return newBreadcrumbs;
  }

}
