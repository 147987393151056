import { get as _get, cloneDeep, isEmpty } from 'lodash';

/**
 * Get the value of an object, and if null or undefined return default value
 * object = {a: {b: {c: 'valor'}}}
 * _.get(object, 'a.b.c')
 */
export function get(object: any, path: string, defaultValue?: any) {

    const value = _get(object, path);
    if (value === null || value === undefined || value === "") {
        return defaultValue;
    }

    return value;
    // return actualValue || value;
}

/**
 * Duplicate object deep
 */
export function deepClone(obj: any) {
    return cloneDeep(obj);
}

/**
 * Sanitize object
 */
export function sanitize(obj:any, setToNull = true) {
    return setToNull
        ? Object.keys(obj).reduce((a: any, k) => { a[k] = obj[k] === '' ? null : obj[k]; return a; }, {})
        : Object.entries(obj).reduce((a, [k, v]) => (v !== null && v !== '' ? { ...a, [k]: v } : a), {});
}
/**
 * Copy value to clipboard
 */
 export function copyToClipboard(value: any, selBox?: any) {
    let remove = false;
    if (!selBox) {
        let remove = true;
        selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
    }
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    if (remove) {
        document.body.removeChild(selBox);
    }
}

export function validEmail(email: string) {
    const regExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return regExp.test(email);
}

export function urlParams(params?: any) {
    if (isEmpty(params)) {
        return '';
    }
    return (params ? ('?' + new URLSearchParams(sanitize(params, false)).toString()) : '');
}

export function objectToFormData(obj, form = null, namespace = null) {
  const fd = form || new FormData();
  let formKey;
  obj = Object.entries(obj).reduce((a, [k, v]) => (v !== null && v !== '' ? { ...a, [k]: v } : a), {});

  for (const property in obj) {
      if (obj.hasOwnProperty(property)) {
          if (namespace) {
              formKey = namespace + '[' + property + ']';
          } else {
              formKey = property;
          }
          let isSimpleArray = true;
          if (!Array.isArray(obj[property])) {
            isSimpleArray = false;
          }
          if (isSimpleArray) {
            for (let index = 0; index < obj[property].length; index++) {
                const element = obj[property][index];
                if (typeof element === 'object') {
                  isSimpleArray = false;
                  break;
                }
            }
          }
          if (typeof obj[property] === 'object' && !(obj[property] instanceof File) && !isSimpleArray) {
              objectToFormData(obj[property], fd, formKey);
          } else {
              if (obj[property] instanceof File) {
                fd.append(formKey, obj[property], obj[property].name);
              }
              else if (isSimpleArray) {
                  fd.append(formKey, JSON.stringify(obj[property]));
              }
              else {
                  fd.append(formKey, obj[property]);
              }
          }
      }
  }
  return fd;
}


