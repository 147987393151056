import { Component } from '@angular/core';
import { EventService } from '../../../@core/services/event.service';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column [attr.class]="layout_class">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
  `,
})
export class OneColumnLayoutComponent {
  layout_class = '';
  constructor(private eventService: EventService) {
    this.eventService.subscribe('layout-class', (cssClass) => {
      this.layout_class = cssClass;
    });
  }
}
