import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UrlService {
    constructor() { }

    home(): string {
        return '/';
    }

    login(): string {
        return '/auth/login';
    }
}
