import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NbAuthService } from '@nebular/auth';
import { UrlService } from '../services/url.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private url: UrlService,
        private authService: NbAuthService
    ) { }

    canActivate() {
        return this.authService.isAuthenticated()
        .pipe(
          tap(authenticated => {
            if (!authenticated) {
              this.router.navigate([this.url.login()]);
            }
          }),
        );
    }
}
