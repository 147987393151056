import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { merge } from 'lodash';
import { User } from '../models/auth.models';
import { get } from '../utils';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    public onUserLogued: EventEmitter<any>;
    public onUserUpdated: EventEmitter<any>;

    constructor(private http: HttpClient) {
        this.currentUserValue = JSON.parse(localStorage.getItem('currentUser')!);
        this.onUserLogued = new EventEmitter<any>();
        this.onUserUpdated = new EventEmitter<any>();
        this.currentUser = new Observable();
        this.currentUserSubject = new BehaviorSubject({});
    }

    public get currentUserValue(): User {
        this.currentUserValue = JSON.parse(localStorage.getItem('currentUser')!);
        return this.currentUserSubject.value;
    }

    public set currentUserValue(value: User) {
        this.currentUserSubject = new BehaviorSubject(value);
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get loggedUserValue(): User {
        return this.currentUserValue;
    }

    public isTokenExpired(user?: User): any {
        if (!user) { user = this.loggedUserValue; }
        if (!user) { return true; }
        const helper = new JwtHelperService();
        return helper.isTokenExpired(user.token.access);
    }

    public isAuthenticated() {
        if (this.currentUserValue && !this.isTokenExpired()) {
            return true;
        }
        return false;
    }

    public getPermissions() {
        return get(this.currentUserValue, 'permissions', []);
    }

    login(email: string, password: string, redirect = true) {
        return this.http.post<any>(environment.apiServer + `/auth/login/`, JSON.stringify({
            username: email,
            password: password
        })).pipe(map(user => {
            this.saveLoginStorage(user, redirect);
            return user;
        }));
    }

    verifyToken(token?: any) {
        return this.http.post<any>(environment.apiServer + '/token/verify/', JSON.stringify({
            token: token || this.currentUserValue.token.access
        }))
    }

    refreshToken() {
        return this.http.post<any>(environment.apiServer + '/token/refresh/', JSON.stringify({
          refresh: this.currentUserValue.token.refresh
        })).pipe(map(data => {
          this.updateStoredUser({
            token: {
              access: data.access,
              refresh: this.currentUserValue.token.refresh
            }
          });
          return data;
        }));
    }

    saveLoginStorage(user: User, redirect = true) {
        if (user && user.token) {
            this.logout('currentUser');
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
            this.onUserLogued.emit(true);
        }
    }

    logout(user = 'currentUser', redirect = true) {
        localStorage.removeItem(user);
        this.currentUserSubject.next({});
        this.onUserLogued.emit(false);
    }

    public updateStoredUser(userData: any) {
        let userInfo = JSON.parse(localStorage.getItem('currentUser') || '');
        userInfo = merge(userInfo, userData);
        localStorage.setItem('currentUser', JSON.stringify(userInfo));
        this.onUserUpdated.emit(userInfo);
        this.currentUserSubject.next(userInfo);
    }

    public register(email: string, password: string) {
        return this.http.post<any>(environment.apiServer + `/auth/register/`, JSON.stringify({
            username: email,
            password: password
        })).pipe(map(user => {
            return user;
        }));
    }

    public resetPassword(email: string) {
        return this.http.post<any>(environment.apiServer + `/auth/recover/`, JSON.stringify({
            username: email
        })).pipe(map(user => {
            return user;
        }));
    }
}
