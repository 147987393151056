import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { assign } from 'lodash';
import { DialogService } from '../services/dialog.service';
import { Router } from '@angular/router';
import { UrlService } from '../services/url.service';
import { deepClone } from '../utils';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  public static rowsCant: number = -1;

  constructor(private injector: Injector,
      private dialog: DialogService,
      private router: Router,
      private url: UrlService) {
  }

  SKIP_URLS = [
    'token/refresh',
    '/auth/login'
  ];

  filterInterceptorRequest(req: HttpRequest<any>) {
    return this.SKIP_URLS.some(url => req.url.includes(url));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any>  {
    // do not intercept request whose urls are filtered by the injected filter
      if (!this.filterInterceptorRequest(req)) {
        return this.authService.isAuthenticatedOrRefresh()
          .pipe(
            switchMap(authenticated => {
              if (authenticated) {
                  return this.authService.getToken().pipe(
                    switchMap((token: NbAuthToken) => {

                      if (JwtInterceptor.rowsCant != -1 && req.url.indexOf('_sz') === -1) {
                        req = req.clone({
                            setParams: {
                                '_sz': JwtInterceptor.rowsCant + ''
                            }
                        });
                      }

                      const JWT = `JWT ${token.getValue()}`;
                      req = req.clone({
                        setHeaders: {
                          Authorization: JWT,
                        },
                      });

                      return next.handle(req);
                    })
                  )
              } else {
                return next.handle(req).pipe(
                  catchError((error: HttpErrorResponse) => {
                    return this.handleError(error, req, next);
                  })
                );
              }
            }),
          )
      } else if (req.url.includes('token/refresh')) {
        const original_body = JSON.parse(req.body);

        req = req.clone({
          body: {
            refresh: original_body.refresh_token
          }
        });

        return next.handle(req);
      } else {
        return next.handle(req);
      }
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

  private handleError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
    let handled = false;
    if (error.status === 404 && (error.error.detail === 'inactive_tenant' || error.error.detail === 'invalid_tenant')) {
        this.dialog.error(error).subscribe();
        handled = true;
    }

    if (error.status === 401) {
        handled = true;

        this.dialog.error(error).subscribe((rs) => {
          location.reload();
        });

    }
    if (error.status === 403) {
        this.dialog.error(error);
    }

    let _error = deepClone(error);

    _error = assign(_error, {
        handled: handled
    });

    return throwError(_error);
}

}
